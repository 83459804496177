import gsap from 'gsap';

var ease = 0.22;

var ball = document.getElementById("mouseFollowBall");
var dot = document.getElementById("mousePointer");

var pos = { x: 0, y: 0 };
var mouse = { x: 0, y: 0 };


window.addEventListener("mousemove", function (e) {
    mouse.x = e.clientX;
    mouse.y = e.clientY;
});

gsap.ticker.add(update);

function update() {
    dot.setAttribute("cx", mouse.x);
    dot.setAttribute("cy", mouse.y);

    pos.x += (mouse.x - pos.x) * ease;
    pos.y += (mouse.y - pos.y) * ease;

    ball.setAttribute("cx", pos.x);
    ball.setAttribute("cy", pos.y);
}